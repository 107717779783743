var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-7 container-fluid"},[_c('b-card'),_c('b-card',{staticClass:"p-4",attrs:{"no-body":""}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-responsive table",staticStyle:{"width":"100%"},attrs:{"header-row-class-name":"thead-light","data":_vm.recipes,"empty-text":_vm.$t('general.empty_table')}},[_c('el-table-column',{attrs:{"label":_vm.$t('recipe.doctor'),"prop":"doctor","min-width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.doctor.title)+_vm._s(row.doctor.name)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('appointment.date'),"prop":"data.date","min-width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("getIntDate")(row.appointment.data.date, 'DD.MM.YYYY ddd'))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('appointment.time'),"prop":"data.time","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("getIntTime")(row.appointment.data.time))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('appointment.delay'),"prop":"data.min","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.appointment.data.min)+" "+_vm._s(_vm.$t('general.min'))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('appointment.type'),"prop":"availability.type","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{class:row.appointment.data.type=='online'?'fas fa-video':'fas fa-building'}),_vm._v(" "+_vm._s(row.appointment.data.type=='online'? _vm.$t('general.conversation_online'):_vm.$t('general.conversation_office'))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('general.created_date'),"prop":"createdAt","min-width":"210","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("getDate")(row.createdAt))+" ")]}}])}),_c('el-table-column',{attrs:{"align":"right","min-width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('base-button',{attrs:{"type":"primary","size":"sm"},on:{"click":function($event){return _vm.openModal(scope.row)}}},[_vm._v(_vm._s(_vm.$t('general.open')))])]}}])})],1),(_vm.pages>1&&!_vm.loading)?_c('b-card-footer',{staticClass:"py-4 d-flex justify-content-end"},[_c('base-pagination',{attrs:{"per-page":_vm.pagination.limit,"total":_vm.total},on:{"change":_vm.changePage},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1):_vm._e()],1),_c('modal',{attrs:{"show":_vm.modal.status,"showClose":false,"backdropClose":false,"hideFooter":true,"size":"lg"},on:{"update:show":function($event){return _vm.$set(_vm.modal, "status", $event)},"close":function($event){_vm.modal.status=false}}},[_c('h6',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.$t('general.recommended_information')))]),_c('hr',{staticClass:"my-2"}),_c('small',{staticClass:"recommendation",domProps:{"innerHTML":_vm._s(_vm.modal.data.text)}}),_c('small',{staticClass:"dblock text-right mt-2"},[_vm._v(_vm._s(_vm._f("getDate")(_vm.modal.data.createdAt)))]),_c('base-button',{staticClass:"ml-auto float-right",attrs:{"type":"link text-danger"},on:{"click":function($event){_vm.modal.status = false}}},[_vm._v(_vm._s(_vm.$t('general.close')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }