<template>
    <div class="pt-7 container-fluid">
        <b-card>

        </b-card>
        <b-card no-body class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="recipes" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')">
                <el-table-column :label="$t('recipe.doctor')" prop="doctor" min-width="240">
                    <template v-slot="{row}">
                        {{row.doctor.title}}{{row.doctor.name}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.date')" prop="data.date" min-width="170">
                    <template v-slot="{row}">
                        {{row.appointment.data.date, 'DD.MM.YYYY ddd' | getIntDate}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.time')" prop="data.time" min-width="120">
                    <template v-slot="{row}">
                        {{row.appointment.data.time | getIntTime}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.delay')" prop="data.min" min-width="120">
                    <template v-slot="{row}">
                        {{row.appointment.data.min}} {{$t('general.min')}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.type')" prop="availability.type" min-width="200">
                    <template v-slot="{row}">
                        <i :class="row.appointment.data.type=='online'?'fas fa-video':'fas fa-building'"></i>
                        {{row.appointment.data.type=='online'? $t('general.conversation_online'):$t('general.conversation_office')}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.created_date')" prop="createdAt" min-width="210" sortable>
                    <template v-slot="{row}">
                        {{row.createdAt | getDate}}
                    </template>
                </el-table-column>
                <el-table-column align="right" min-width="110">
                    <template slot-scope="scope">
                        <base-button type="primary" size="sm" @click="openModal(scope.row)">{{$t('general.open')}}</base-button>
                    </template>
                </el-table-column>
            </el-table>
            <b-card-footer class="py-4 d-flex justify-content-end" v-if="pages>1&&!loading">
                <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
            </b-card-footer>
        </b-card>

        <modal :show.sync="modal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="modal.status=false" size="lg">
            <h6 class="modal-title">{{$t('general.recommended_information')}}</h6>
            <hr class="my-2">
            <small class="recommendation" v-html="modal.data.text"></small>
            <small class="dblock text-right mt-2">{{modal.data.createdAt | getDate}}</small>
            <base-button type="link text-danger" class="ml-auto float-right" @click="modal.status = false">{{$t('general.close')}}</base-button>
        </modal>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { RECIPE_REQUEST } from '@/store/modules/define';
import { handleError } from '@/utils/func';
let self;

export default {
    data() {
        return {
            loading: false,
            modal: {
                status: false,
                loading: false,
                data: {}
            },
            status: {
                true: 'success',
                false: 'danger'
            },
            pagination: {
                limit: 10,
                page: 1
            },
            filter: {}
        };
    },
    computed: {
        ...mapGetters({
            recipes: 'getRecipes',
            pages: 'getRecipePages',
            total: 'getRecipeTotal'
        })
    },
    methods: {
        openModal(row) {
            self.modal.data = _.cloneDeep(row);
            self.modal.loading = false;
            self.modal.status = true;
        },
        changePage(val) {
            self.pagination.page = val;
            self.getData();
        },
        getData() {
            self.loading = true;
            self.$store
                .dispatch(RECIPE_REQUEST, { pagination: self.pagination, filter: self.filter, fields: '-_id -updatedAt' })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        }
    },
    created() {
        self = this;
        self.getData();
    }
};
</script>
